<template>
	<div v-if="!loading">
		<b-tabs vertical content-class="col-12 col-md-10 mt-1 mt-md-0" pills nav-wrapper-class="col-md-2 col-12" nav-class="nav-left" lazy>
			<b-tab title="Items">
				<base-table :items="projectItems" :headers="headers" paginate table-top>
					<template #action>
						<new-sub-project-item :key="newFormKey" :data="itemData" :items="items" @done="handleDone" @hidden="resetForm" />
					</template>
					<template #cell(actions)="{ item }">
						<b-button
							v-b-modal.new-item-modal
							v-b-tooltip.hover.top="'Edit'"
							variant="outline-secondary"
							class="btn-icon cursor-pointer mr-1"
							size="sm"
							@click="editItem(item)"
						>
							<feather-icon icon="Edit3Icon" size="16" />
						</b-button>
						<b-button
							v-b-tooltip.hover.top="'Delete'"
							variant="outline-danger"
							class="btn-icon cursor-pointer"
							size="sm"
							@click="deleteItem(item)"
						>
							<feather-icon icon="Trash2Icon" size="16" />
						</b-button>
					</template>
				</base-table>
			</b-tab>
			<b-tab title="Report">
				<base-table :headers="reportHeaders" :items="itemsReports" table-top paginate>
					<template #cell(quantity)="data">
						<span> {{ data.value }} {{ data.item.unit }} </span>
					</template>
					<template #cell(executed_quantity)="data">
						<span> {{ data.value }} {{ data.item.unit }} </span>
					</template>
					<template #cell(progress)="data">
						<span>{{ data.item.progress }}%</span>
						<b-progress height="1.4rem" show-value>
							<b-progress-bar :value="data.item.progress" :label="`${data.item.progress}%`" />
						</b-progress>
					</template>
				</base-table>
			</b-tab>
		</b-tabs>
	</div>
	<div v-else class="">
		<div class="text-center text-primary mt-3 mb-2">
			<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
			<span class="d-block mt-1">Loading...</span>
		</div>
	</div>
</template>

<script>
import { BSpinner, VBTooltip, BButton, BProgress, BProgressBar, BTabs, BTab } from 'bootstrap-vue';
import api from '@/utils/api';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import NewSubProjectItem from './new-sub-project-item.vue';

export default {
	name: 'SubProjectItems',

	components: {
		BSpinner,
		BButton,
		BProgress,
		BProgressBar,
		BTabs,
		BTab,
		BaseTable,
		NewSubProjectItem,
	},

	directives: {
		'b-tooltip': VBTooltip,
	},

	data: () => ({
		items: [],
		projectItems: undefined,
		itemData: undefined,
		headers: [
			{ key: 'id' },
			{ key: 'name', label: 'Main item' },
			{ key: 'unit', label: 'Unit' },
			{ key: 'quantity' },
			{ key: 'actions' },
		],

		reportHeaders: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'name', label: 'Item', sortable: true },
			{ key: 'quantity', label: 'Expected Quantity', sortable: true },
			{ key: 'executed_quantity', label: 'Executed Quantity', sortable: true },
			{ key: 'progress' },
		],

		itemsReports: [
			{
				id: '11347',
				name: 'ملاجة داخلية للاسقف',
				unit: 'M2',
				quantity: '2.00',
				executed_quantity: '1.50',
				price: '220.000000',
				progress: '150.0',
			},
			{
				id: '11434',
				name: 'ملاجة داخلية للجدران',
				unit: 'M2',
				quantity: '1',
				executed_quantity: '1',
				price: '55.000000',
				progress: '100.00',
			},
			{
				id: '12848',
				name: 'ملاجة خارجية للجدران',
				unit: 'M2',
				quantity: '4',
				executed_quantity: '3',
				price: '28.000000',
				progress: '75.00',
			},
			{
				id: '51106',
				name: 'أعمال التشريب (الصبصبة) بالخلطة الجصية',
				unit: 'M2',
				quantity: '4',
				executed_quantity: '2',
				price: '12.000000',
				progress: '50.0',
			},
			{
				id: '61154',
				name: 'اعمال السوقلة الخارجية للواجهات',
				unit: 'M2',
				quantity: '6',
				executed_quantity: '5',
				price: '9.000000',
				progress: '83.333',
			},
		],

		loading: false,
		newFormKey: 0,
	}),

	async created() {
		this.loading = true;

		try {
			await this.loadItems();
			await this.loadSubProjectItems();
			this.loading = false;
		} catch (error) {
			console.log(error);
			this.loading = false;
		}
	},

	methods: {
		async loadItems() {
			this.items = await store.dispatch('newItems/getItems');
		},

		async loadSubProjectItems() {
			this.loading = true;

			try {
				const { data } = await api.call({ path: `sub-projects/${this.$route.params.subId}/items`, method: 'GET', noLoading: true });
				this.projectItems = data.data;
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.loading = false;
			}
		},

		editItem(item) {
			this.itemData = {
				id: item.id,
				item_id: item.item.id,
				quantity: item.quantity,
				sub_items: item.sub_items,
			};
		},

		async deleteItem(item) {
			await store.dispatch('subProjects/deleteItem', {
				itemId: item.id,
				subProjectId: this.$route.params.subId,
			});

			this.loadSubProjectItems();
		},

		handleDone() {
			this.resetForm();
			this.loadSubProjectItems();
		},

		resetForm() {
			this.itemData = undefined;
			// eslint-disable-next-line no-plusplus
			this.newFormKey++;
		},
	},
};
</script>

<style lang="scss" scoped>
.repeater-form {
	overflow: hidden;
	transition: 0.35s height;
}
</style>
