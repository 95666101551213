<template>
	<div class="">
		<b-button v-b-modal.new-item-modal variant="primary"> New item </b-button>
		<b-modal
			id="new-item-modal"
			ref="newItemModal"
			no-close-on-backdrop
			size="lg"
			:title="isEdit ? `Edit item` : `Add new item`"
			centered
			lazy
			@hidden="resetModal"
		>
			<validation-observer ref="formRules">
				<b-form ref="form">
					<span class="mb-1 d-block">Select main item and specify its quantity</span>
					<b-row>
						<b-col>
							<b-form-group label="Main item" label-for="item">
								<validation-provider #default="{ errors }" name="Main Item" rules="required">
									<v-select
										id="item"
										v-model="formData.item_id"
										label="name"
										:options="items"
										:reduce="(o) => o.id"
										:state="errors.length > 0 ? false : null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group label="Main item quantity" label-for="quantity">
								<validation-provider #default="{ errors }" name="Quantity" rules="required">
									<b-input-group>
										<b-form-input
											id="quantity"
											v-model="formData.quantity"
											type="number"
											placeholder="Quantity"
											:state="errors.length > 0 ? false : null"
										/>
										<b-input-group-append is-text>{{ mainUnit }}</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
					</b-row>
					<hr />
					<template v-if="subItems.length">
						<span class="mb-50 d-block">Specify sub items quantities and prices</span>
						<div
							v-for="subItem in subItems"
							:key="subItem.id"
							class="px-1 py-1 mt-50 border rounded"
							style="background-color: #f9f9f9"
						>
							<p class="mb-0">{{ subItem.name }}</p>

							<b-row>
								<b-col>
									<b-form-group label="Quantity" label-for="quantity">
										<validation-provider #default="{ errors }" name="Quantity" rules="required">
											<b-input-group>
												<b-form-input
													id="quantity"
													v-model="subItem.quantity"
													type="number"
													placeholder="Quantity"
													:state="errors.length > 0 ? false : null"
												/>
												<b-input-group-append is-text>{{ subItem.unit }}</b-input-group-append>
											</b-input-group>
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Unit Price" label-for="price">
										<validation-provider #default="{ errors }" name="Price" rules="required">
											<b-input-group>
												<b-form-input
													id="price"
													v-model="subItem.price"
													type="number"
													placeholder="Unit Price"
													:state="errors.length > 0 ? false : null"
												/>
												<b-input-group-append is-text>YR</b-input-group-append>
											</b-input-group>
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</template>
				</b-form>
			</validation-observer>

			<template #modal-footer="{ cancel }">
				<b-button variant="outline-secondary" @click="cancel">Cancel</b-button>
				<loading-btn variant="primary" :loading="loading" @click="handleOk">Save</loading-btn>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BRow, BCol, BFormGroup, BInputGroup, BInputGroupAppend, BForm, BButton } from 'bootstrap-vue';
import LoadingBtn from '@/components/LoadingBtn.vue';
import { required } from '@validations';
import vSelect from 'vue-select';
import store from '@/store';

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BRow,
		BCol,
		BFormGroup,
		BInputGroup,
		BInputGroupAppend,
		BForm,
		BButton,
		LoadingBtn,

		vSelect,
	},

	props: {
		data: {
			type: Object,
			default: () => ({
				item_id: '',
				quantity: '',
			}),
		},
		items: {
			type: Array,
			default: () => [],
		},
	},

	data: () => ({
		required,
		loading: false,
		subItems: [],
	}),

	computed: {
		formData() {
			return this.data;
		},

		isEdit() {
			return !!this.data.id;
		},

		mainUnit() {
			if (!this.formData.item_id) return '';
			return this.items.find((i) => i.id === this.formData.item_id)?.unit;
		},
	},

	watch: {
		'formData.item_id': {
			handler(val) {
				if (val) {
					const data = this.items.find((i) => i.id === this.formData.item_id)?.sub_items ?? [];
					this.subItems = data.map((i) => ({
						...i,
						quantity: '',
						price: '',
					}));
				}
			},
		},
		data: {
			handler(val) {
				if (val.sub_items) {
					this.subItems = Array.from(val.sub_items);
				}
			},
			deep: true,
			immediate: true,
		},
	},

	methods: {
		resetModal() {
			this.$emit('hidden');
		},

		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},

		async handleSubmit() {
			const success = await this.$refs.formRules.validate();
			if (!success) return;

			this.loading = true;

			const data = {
				subProjectId: this.$route.params.subId,
				data: {
					...this.formData,
					sub_items: this.subItems.map((i) => ({
						sub_item_id: i.id,
						price: i.price,
						quantity: i.quantity,
					})),
				},
			};

			try {
				if (this.isEdit) {
					await store.dispatch('subProjects/updateSubProjectItem', data);
				} else {
					await store.dispatch('subProjects/addSubProjectItem', data);
				}

				this.loading = false;
			} catch (error) {
				console.log(error);
				this.loading = false;
			}

			this.$emit('done');
		},

		getSubItems(itemId) {
			return itemId !== '' ? this.subItems.filter((i) => i.item_id === itemId) : [];
		},
	},
};
</script>

<style lang="scss" scoped></style>
