var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.new-item-modal",modifiers:{"new-item-modal":true}}],attrs:{"variant":"primary"}},[_vm._v(" New item ")]),_c('b-modal',{ref:"newItemModal",attrs:{"id":"new-item-modal","no-close-on-backdrop":"","size":"lg","title":_vm.isEdit ? "Edit item" : "Add new item","centered":"","lazy":""},on:{"hidden":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":cancel}},[_vm._v("Cancel")]),_c('loading-btn',{attrs:{"variant":"primary","loading":_vm.loading},on:{"click":_vm.handleOk}},[_vm._v("Save")])]}}])},[_c('validation-observer',{ref:"formRules"},[_c('b-form',{ref:"form"},[_c('span',{staticClass:"mb-1 d-block"},[_vm._v("Select main item and specify its quantity")]),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Main item","label-for":"item"}},[_c('validation-provider',{attrs:{"name":"Main Item","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"item","label":"name","options":_vm.items,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.item_id),callback:function ($$v) {_vm.$set(_vm.formData, "item_id", $$v)},expression:"formData.item_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Main item quantity","label-for":"quantity"}},[_c('validation-provider',{attrs:{"name":"Quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"quantity","type":"number","placeholder":"Quantity","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.quantity),callback:function ($$v) {_vm.$set(_vm.formData, "quantity", $$v)},expression:"formData.quantity"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v(_vm._s(_vm.mainUnit))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),(_vm.subItems.length)?[_c('span',{staticClass:"mb-50 d-block"},[_vm._v("Specify sub items quantities and prices")]),_vm._l((_vm.subItems),function(subItem){return _c('div',{key:subItem.id,staticClass:"px-1 py-1 mt-50 border rounded",staticStyle:{"background-color":"#f9f9f9"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(subItem.name))]),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Quantity","label-for":"quantity"}},[_c('validation-provider',{attrs:{"name":"Quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"quantity","type":"number","placeholder":"Quantity","state":errors.length > 0 ? false : null},model:{value:(subItem.quantity),callback:function ($$v) {_vm.$set(subItem, "quantity", $$v)},expression:"subItem.quantity"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v(_vm._s(subItem.unit))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Unit Price","label-for":"price"}},[_c('validation-provider',{attrs:{"name":"Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"price","type":"number","placeholder":"Unit Price","state":errors.length > 0 ? false : null},model:{value:(subItem.price),callback:function ($$v) {_vm.$set(subItem, "price", $$v)},expression:"subItem.price"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v("YR")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)})]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }